import { Box, Grid, Typography } from "@mui/material";
import React, { FC } from "react";

const DashboardOverview: FC = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box m={1} display="flex" justifyContent="center">
            <Typography component="span" variant="h4">
              Request Help
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={2} mr={2} mb={2}>
            <Typography component="span" align="justify" variant="body2" style={{fontWeight: "bold"}}>
              Click <a href="https://vcc.readyop.com/fs/4c93/5f4ef914" rel="noreferrer" target="_blank">here</a> to tell the VCC Project Team about any issues you experience or feedback you have on the VCC system.
              Click <a href={`${process.env.PUBLIC_URL}/VCC%20User%20Guide%202.2.pdf`} rel="noreferrer" target="_blank">here</a> to access the current user guide.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={1} display="flex" justifyContent="center">
            <Typography component="span" variant="h4">
              VCC Dashboard
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box m={2}>
            <Typography component="span" align="center" variant="body1">
              The dashboard provides Situational Awareness of all active VCC
              Incidents and Integrated Dispatch Feed Events
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} mb={0} pb={0} display="flex" justifyContent="center">
            <Typography component="span" variant="h6">
              Active VCC Incidents
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={2} mr={2} mb={2}>
            <Typography component="span" align="justify" variant="body2">
              This component shows all active VCC Incidents, with the most
              recent incidents listed at the top. Click on an item to zoom to it
              on the map, or click the
              <Box fontStyle="italic" display="inline">
                View VCC Incident icon
              </Box>{" "}
              to go to the VCC Incident Model Management Page for that VCC
              Incident.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} mb={0} pb={0} display="flex" justifyContent="center">
            <Typography component="span" variant="h6">
              VCC Incident Map
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={2} mr={2} mb={2}>
            <Typography component="span" align="justify" variant="body2">
              This map shows all active VCC Incidents and Dispatch Events. You
              can select and deselect the checkboxes to show or hide VCC
              Incidents and Dispatch Events. Click a red VCC Incident to go to
              its VCC Incident Model Management Page and click a yellow Dispatch
              Event to see its additional details.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} mb={0} pb={0} display="flex" justifyContent="center">
            <Typography component="span" variant="h6">
              Recent Dispatch Events
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box ml={2} mr={2} mb={2}>
            <Typography component="span" align="justify" variant="body2">
              An up to date view of recent source agency events that can be used
              to create VCC Incidents. This is the same data presented on the{" "}
              <Box fontStyle="italic" display="inline">
                Feed
              </Box>{" "}
              page.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardOverview;
