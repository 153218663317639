import {
  Box,
  Grid,
  Paper,
  Button,
  Badge,
  Typography,
  IconButton,
  Theme,
} from "@mui/material";
import moment from "moment";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import makeStyles from "@mui/styles/makeStyles";
import RoomIcon from "@mui/icons-material/Room";
import { DispatchEventFeedModel, IRootReducer } from "../../../reducers";
import GridLoadingOverlay from "../../common/loading-overlay";
import { TruncateSourceIdUtil } from "../../../utils/string-utils";
import { Agency } from "../../../enums/agency";
import AdditionalDetailsModal from "../additional-details-modal";
import { Link } from "react-router-dom";
import IDFGridHeader from "./idf-grid-header";
import { useDispatch, useSelector } from "react-redux";
import { DictionaryFromArrayUtil } from "../../../utils/array-utils";
import {
  getDispatchFeedEventRequest, getDispatchFeedEventsRequest, setFeedAreaRequest, setFeedRegionRequest,
} from "../../../actions";
import { ShortIdUtil } from "../../../utils/id-utils";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridOverlay,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { FeedView } from "../../../enums/feed-view";
import { AgencyChip } from "../../common/agency-chip";
import { setDispatchDataGridGeoRequest, setSelectedEventIdsRequest, updateViewportRequest } from "../../../actions/map";
import _ from "lodash";
import { renderCellExpand } from "../../common/data-grid/cell-expander";
import { UTCFromTimeFrameUtil, UTCToOneMinuteAheadUtil } from "../../../utils/date-utils";
import { isI5AreaSelectedUtil, isNWRegionSelectedUtil, isWSPSelectedUtil, noAreaOrRegionSelectedUtil } from "../../../utils/feed-utils";

const useStyles = makeStyles((theme: Theme) => ({
  pinnedEvent: {
    backgroundColor: theme.pins.PinHighlight,
  },
  mapIconOpen: {
    color: "black",
  },
  mapIconClosed: {
    color: theme.marker.ClosedDispatchEventIFD,
  },
  eventNumberOpen: {
    color: theme.palette.primary.main,
  },
  eventNumberClosed: {
    color: theme.marker.ClosedDispatchEventIFD,
  },
  closedIDFRow: {
    color: theme.marker.ClosedDispatchEventIFD,
  },
  openIDFRow: {
    backgroundColor: "white",
  },
  badge: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const defaultFilter = [Agency.WSP_I5, Agency.SFD, Agency.SPD, Agency.KCM];
const defaultMainGridOffset = "175px";
const gridSpaceMargin = "8px";
const titleOffset = "32px";
const loadMoreButtonOffset = "40px";
const defaultPinGridSize = 175;
const defaultToolbarSize = 32;

export interface DispatchGridRow {
  id: string;
  eventNumber: string;
  eventStartDate: string;
  lastUpdateDate: string;
  agency: string;
  location?: string;
  latitude?: number;
  longitude?: number;
  eventType?: string;
  associatedIncId?: string;
  noteworthyReason?: string;
  status?: string;
}

interface DispatchEventFeedGridProps {
  props: {
    isDashDisplay?: boolean;
    title?: string;
  };
}

const DispatchEventFeedGrid: FC<DispatchEventFeedGridProps> = ({ props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [gridIDFData, setGridIDFData] = useState([] as DispatchGridRow[]);
  const [pinGridData, setPinGridData] = useState([] as DispatchGridRow[]);
  const [isGridLoading, setIsGridLoading] = useState(true);
  const [agencyFilters, setAgencyFilters] = useState(defaultFilter);
  const [visibleDataGridRows, setVisibleDataGridRows] = useState({});
  const [gridFilterModel, setGridFilterModel] = useState({} as GridFilterModel);
  const [dataGridFilteredRowIds, setDataGridFilteredRowIds ] = useState([] as string[]);
  const [filterByDataGrid, setFilterByDataGrid] = useState(false);
  const [addtlDetailsOpen, setaddtlDetailsOpen] = useState(false);
  const [pinPaginationModel, setPinPaginationModel] = useState({
    pageSize: 10,
    page: 0
  })
  const [idfPaginationModel, setIdfPaginationModel] = useState({
    pageSize: 100,
    page: 0
  })
  const [addtlDetails, setAddtlDetails] = useState(
    {} as DispatchEventFeedModel
  );
  const [mostRecentSelectedEvent, setMostRecentSelectedEvent] = useState(
    {} as DispatchEventFeedModel
  );
  const [selectedIDFEvents, setSelectedIDFEvents] = useState(
    [] as DispatchEventFeedModel[]
  );
  const [selectedPinnedEvents, setSelectedPinnedEvents] = useState(
    [] as DispatchEventFeedModel[]
  );
  const [mainGridHeight, setMainGridHeight] = useState(
    "calc(100% - " + defaultMainGridOffset + ")"
  );
  const [pinGridHeight, setPinGridHeight] = useState(defaultPinGridSize + "px");
  const gridHeaderRef = useRef<any>(null);

  const dispatchStore = useSelector(
    (state: IRootReducer) => state.dispatchEventFeedReducer
  );

  const showLoadingAnimation = useCallback(() => {
    const timeoutId = setTimeout(() => {
      setIsGridLoading(false);
    }, 1250);

    return function cleanup() {
      clearTimeout(timeoutId);
    };
  }, []);

  const calcGridHeight = useCallback(() => {
    return (
      "calc(100% - (" +
      gridHeaderRef.current.clientHeight +
      "px + " +
      titleOffset +
      ") - ( " +
      pinGridHeight +
      " + " +
      (dispatchStore.dispatchEvents.pagingKeys ? loadMoreButtonOffset : "0px" )+ 
      " + " +
      gridSpaceMargin +
      "))"
    );
  }, [dispatchStore.dispatchEvents.pagingKeys, pinGridHeight]);

  // Init Grid Height based on current window size
  useEffect(() => {
    let heightToUse = calcGridHeight();
    setMainGridHeight(
      heightToUse ?? "calc(100% - " + defaultMainGridOffset + "px"
    );
  }, [calcGridHeight]);

  // Init and Update Pin Grid Height on pin count
  useEffect(() => {
    let heightToUse = props.isDashDisplay
      ? defaultPinGridSize
      : defaultPinGridSize + defaultToolbarSize;
    if (dispatchStore.pinnedEvents.length < 2) {
      heightToUse = props.isDashDisplay
        ? (heightToUse / 2)
        : (heightToUse / 1.5);
    }

    setPinGridHeight(heightToUse + "px");
  }, [dispatchStore.pinnedEvents, props.isDashDisplay]);

  // Init map selected - clear selection on initial load
  useEffect(() => {
    dispatch(
      setSelectedEventIdsRequest(DictionaryFromArrayUtil([] as string[]))
    );
  }, [dispatch]);

  // Update additional details
  useEffect(() => {
    setAddtlDetails(dispatchStore.dispatchEvent);
  }, [dispatchStore.dispatchEvent]);

  // Update gridData from latest dispatch events loaded
  useEffect(() => {
    setIsGridLoading(true);
    let gridRows: DispatchGridRow[] = [];

    if(dispatchStore.dispatchEvents.data){
      gridRows = dispatchStore.dispatchEvents.data.reduce(
        function (results: DispatchGridRow[], r, index) {

          if (agencyFilters.includes(r.agency as Agency) || isWSPSelectedUtil(agencyFilters, r.agency)) {
            results.push({
              id: index + r.agency,
              eventNumber: r.eventNumber,
              eventStartDate: r.eventDate,
              lastUpdateDate: r.lastUpdateDt,
              agency: r.agency,
              location: r.location,
              latitude: r.latitude,
              longitude: r.longitude,
              eventType: r.eventType,
              associatedIncId: r.associatedIncId,
              noteworthyReason: r.noteworthyReason,
              status: r.status,
            });
          }
          return results;
        },
        []
      );
    }
    
    // Apply any datagrid filters if set and then update the redux store for dispatch events that should be displayed
    if(filterByDataGrid){
      let filteredMapData = gridRows.filter(r => dataGridFilteredRowIds.includes(r.id));
      dispatch(setDispatchDataGridGeoRequest(filteredMapData));
    }
    else{
      dispatch(setDispatchDataGridGeoRequest(gridRows));
    }

    let pinRows: DispatchGridRow[] = dispatchStore.pinnedEvents.reduce(
      function (results: DispatchGridRow[], r, index) {
        if (agencyFilters.includes(r.agency as Agency) || isWSPSelectedUtil(agencyFilters, r.agency)) {
          results.push({
            id: index + r.agency,
            eventNumber: r.eventNumber,
            eventStartDate: r.eventDate,
            lastUpdateDate: r.lastUpdateDt,
            agency: r.agency,
            location: r.location,
            latitude: r.latitude,
            longitude: r.longitude,
            eventType: r.eventType,
            associatedIncId: r.associatedIncId,
            noteworthyReason: r.noteworthyReason,
            status: r.status,
          });
        }
        return results;
      },
      []
    );

    // Filter out User Pins from the main feed
    let pinEventNumbers = pinRows.map((p) => p.eventNumber);
    setGridIDFData([
      ...gridRows.filter((g) => !pinEventNumbers.includes(g.eventNumber)),
    ]);
    setPinGridData([...pinRows]);
    showLoadingAnimation();

    // Review: Check the height of the grid in case the screen resizes
    let heightToUse = calcGridHeight();
    setMainGridHeight(
      heightToUse ?? "calc(100% - " + defaultMainGridOffset + ""
    );
  }, [dispatchStore.dispatchEvents.data, dispatchStore.pinnedEvents, dispatchStore.feedView, agencyFilters, pinGridHeight, calcGridHeight, showLoadingAnimation, dispatch, dataGridFilteredRowIds, filterByDataGrid]);

  const showToolbar = (): JSX.Element | null => {
    if (!props?.isDashDisplay) {
      return (
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
      );
    }
    return null;
  }
  
  const handleDispatchPaging = () => {
    dispatch(getDispatchFeedEventsRequest(
      UTCFromTimeFrameUtil(dispatchStore.timeFrame), 
      UTCToOneMinuteAheadUtil(), 
      dispatchStore.showOpenDispatchesOnly, 
      dispatchStore.region,
      dispatchStore.area,
      false, // Set map data
      true) // Set paging
    );
  }
  
  const handleAgencySelect = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedFilters = [...agencyFilters];
    if (event.target.checked === false) {
      let index = updatedFilters.indexOf(event.target.name as Agency);
      if (index > -1) updatedFilters.splice(index, 1);
    } else {
      updatedFilters.push(event.target.name as Agency);
    }

    // Update area and region if WSP_I5/NW is updated
    if(isNWRegionSelectedUtil(agencyFilters, updatedFilters)){
      dispatch(setFeedRegionRequest("NW"));
      dispatch(setFeedAreaRequest(""));
    }
    else if(isI5AreaSelectedUtil(agencyFilters, updatedFilters)){
      dispatch(setFeedAreaRequest("I5"));
      dispatch(setFeedRegionRequest(""));
    }
    else if(noAreaOrRegionSelectedUtil(updatedFilters)){
      dispatch(setFeedAreaRequest(""));
      dispatch(setFeedRegionRequest(""));
    }

    setAgencyFilters(updatedFilters);
  };

  const handleIDFSelection = (rowIds: GridRowSelectionModel) => {
    let selectedRowsEventNumbers: string[] = gridIDFData
      .filter((d) => rowIds.includes(d.id))
      .map((s) => s.eventNumber);

    let selectedEvents: DispatchEventFeedModel[] =
      dispatchStore.dispatchEvents.data.filter((d) =>
        selectedRowsEventNumbers.includes(d.eventNumber)
      );

    setSelectedIDFEvents(selectedEvents);
    setMostRecentSelectedEvent(
      selectedEvents.find(
        (d) =>
          d.eventNumber ===
          selectedRowsEventNumbers[selectedRowsEventNumbers.length - 1]
      )!
    );

    if (props.isDashDisplay) {
      dispatch(
        setSelectedEventIdsRequest(
          DictionaryFromArrayUtil(selectedRowsEventNumbers)
        )
      );
    }
  };

  const handlePinnedSelection = (rowIds: GridRowSelectionModel) => {
    let selectedRowsEventNumbers: string[] = pinGridData
      .filter((d) => rowIds.includes(d.id))
      .map((s) => s.eventNumber);

    let selectedEvents: DispatchEventFeedModel[] =
      dispatchStore.dispatchEvents.data.filter((d) =>
        selectedRowsEventNumbers.includes(d.eventNumber)
      );

    setSelectedPinnedEvents(selectedEvents);
    setMostRecentSelectedEvent(
      selectedEvents.find(
        (d) =>
          d.eventNumber ===
          selectedRowsEventNumbers[selectedRowsEventNumbers.length - 1]
      )!
    );
    if (props.isDashDisplay) {
      dispatch(
        setSelectedEventIdsRequest(
          DictionaryFromArrayUtil(selectedRowsEventNumbers)
        )
      );
    }
  };

  const handleAdditionalDetailsOpen = (id: string) => {
    dispatch(getDispatchFeedEventRequest(id));
    setaddtlDetailsOpen(true);
  };

  // Handle DataGrid filters - DataGrid hides rows without changing gridIDFData
  const handleVisibleRowsChange = (visibleRowsLookup: Object) => {
    if(!_.isEqual(visibleRowsLookup, visibleDataGridRows)){
      if(_.isEmpty(visibleRowsLookup) && (gridFilterModel?.items?.length === 0 || gridFilterModel?.items[0]?.value === "")){
        // No items and no filterModel in this array means none are hidden by the grid, update the map
        // to what is currently stored in gridIDFData
        setDataGridFilteredRowIds([]);
        setFilterByDataGrid(false);
      }
      else{
        let visibleRowIds: string[] = _.keys(_.pickBy(visibleRowsLookup));
        setDataGridFilteredRowIds(visibleRowIds);
        setFilterByDataGrid(true);
      }
      
      setVisibleDataGridRows(visibleRowsLookup);
    }
  }

  const columnVisibility = {
    zoomTo: props?.isDashDisplay ?? false,
    eventStartDate: !(props?.isDashDisplay ?? false),
    noteworthyReason: dispatchStore.feedView !== FeedView.ALL,
    associatedIncId: dispatchStore.feedView !== FeedView.NOTEWORTHY
  }
  const columns: GridColDef[] = [
    {
      field: "zoomTo",
      headerName: "Map",
      width: 60,
      filterable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      align: "center" as const,
      renderCell: (params: GridRenderCellParams) => {
        const latitude =  params.row.latitude;
        const longitude = params.row.longitude;
        const status = params.row.status;
        if (latitude) {
          return (
            <IconButton
              onClick={() =>
                dispatch(updateViewportRequest(latitude as number, longitude as number, 14))
              }
              size="large"
            >
              {(status as string) === "CLOSED" ? (
                <RoomIcon className={classes.mapIconClosed} />
              ) : (
                <RoomIcon className={classes.mapIconOpen} />
              )}
            </IconButton>
          );
        }
      },
    },
    {
      field: "eventNumber",
      headerName: "Event Number",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const sourceId = params.row.eventNumber;
        const agency = params.row.agency;
        const associatedIncId = params.row.associatedIncId;
        const status = params.row.status;
        return (
          <Box>
            <Badge
              style={{ top: "10px" }}
              badgeContent={associatedIncId ? 1 : 0}
              classes={{ badge: classes.badge }}
              variant="dot"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Button
                style={{ top: "-10px" }}
                type="button"
                className={
                  (status as string) === "CLOSED"
                    ? classes.eventNumberClosed
                    : classes.eventNumberOpen
                }
                data-rum-id={`dispatch-grid__${sourceId}`}
                onClick={() => handleAdditionalDetailsOpen(sourceId as string)}
              >
                {TruncateSourceIdUtil(sourceId as string, agency as string)}
              </Button>
            </Badge>
          </Box>
        );
      },
    },
    {
      field: "eventStartDate",
      headerName: "Event Start Time",
      flex: 0.75,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const startDate = params.row.eventStartDate;
        return <Box>{moment(startDate as string).format("HH:mm:ss")}</Box>;
      },
    },
    {
      field: "lastUpdateDate",
      headerName: "Last Updated Time",
      flex: 0.75,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const lastUpdateDate = params.row.lastUpdateDate;
        return <Box>{moment(lastUpdateDate as string).format("HH:mm:ss")}</Box>;
      },
    },
    {
      field: "agency",
      headerName: "Agency",
      filterable: false,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      flex: props?.isDashDisplay ? 0.65 : 0.45,
      renderCell: (params: GridRenderCellParams) => {
        const agency = params.row.agency;
        const status = params.row.status;
        return <AgencyChip props={{
            agency: agency as string,
            isClosed: (status as string) === "CLOSED"
          }} 
        />;
      },
    },
    { field: "eventType", headerName: "Event Type", flex: 1.25, renderCell: renderCellExpand },
    { field: "location", headerName: "Location", flex: 1.25, renderCell: renderCellExpand },
    {
      field: "noteworthyReason",
      headerName: "Noteworthy Reason",
      flex: 1.1,
      renderCell: (params: GridRenderCellParams) => {
        const assocIncId = params.row.associatedIncId;
        if (assocIncId) {
          return (
            <Link to={"/incident-model/" + assocIncId}>Associated VCC IM</Link>
          );
        } else {
          return params.row.noteworthyReason;
        }
      },
    },
    {
      field: "associatedIncId",
      headerName: "VCC Incident",
      flex: 1.1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const assocIncId = params.row.associatedIncId;
        return (
          <Box m={1}>
            <Link to={"/incident-model/" + assocIncId} data-rum-id={`incident-model-grid-${assocIncId}`}>
              {assocIncId ? ShortIdUtil(assocIncId.toString()) : ""}
            </Link>
          </Box>
        );
      },
    },
  ];

  const noPinned = useMemo(() => (
    <GridOverlay>No pinned dispatches</GridOverlay>
  ), []);

  const noFilteredPin = useMemo(() => (
    <GridOverlay>No filtered pinned results</GridOverlay>
  ), []);

  const noRecent = useMemo(() => (
    <GridOverlay>
      {dispatchStore.feedView === "All"
        ? "No recent dispatches"
        : "No Noteworthy Dispatches"}
    </GridOverlay>
  ), [dispatchStore.feedView]);

  const NoFiltered = useMemo(() => (
    <GridOverlay>No filtered dispatch results</GridOverlay>
  ), []);

  return (
    <Box style={{ height: "85vh" }}>
      <Box>
        {props.isDashDisplay && (
          <Grid container style={{ height: "32px" }}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h6">Recent Dispatch Events</Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Grid container style={{ height: "100%" }}>
        <Grid item ref={gridHeaderRef} xs={12}>
          <Paper variant="outlined">
            <IDFGridHeader
              props={{
                mostRecentSelectedEvent: mostRecentSelectedEvent,
                selectedIDFEvents: selectedIDFEvents,
                selectedPinnedEvents: selectedPinnedEvents,
                isDashDisplay: props.isDashDisplay,
                agencyFilters: agencyFilters,
                handleAgencySelect: handleAgencySelect,
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} style={{ height: "100%" }}>
          {/* Pinned Grid */}
          <Box style={{ display: "flex", height: pinGridHeight }}>
            <Box style={{flexGrow: 1, minWidth: 0 }}>
              <DataGrid
                rows={pinGridData}
                columns={columns}
                columnVisibilityModel={columnVisibility}
                getRowClassName={() => classes.pinnedEvent}
                paginationModel={pinPaginationModel}
                onPaginationModelChange={setPinPaginationModel}
              checkboxSelection
                disableRowSelectionOnClick
                density="compact"
                disableColumnSelector={true}
                slots={{
                  loadingOverlay: GridLoadingOverlay,
                  toolbar: showToolbar,
                  noRowsOverlay: () => (noPinned),
                  noResultsOverlay: () => (noFilteredPin),
                }}
                loading={isGridLoading}
                onRowSelectionModelChange={(selection: GridRowSelectionModel) =>
                  handlePinnedSelection(selection)
                }
                hideFooter
              />
            </Box>
          </Box>
          {/* IDF Grid */}
          <Box style={{ display: "flex", height: mainGridHeight, marginTop: gridSpaceMargin }}>
            <Box style={{flexGrow: 1, minWidth: 0 }}>
              <DataGrid
                rows={gridIDFData}
                columns={columns}
                columnVisibilityModel={columnVisibility}
                paginationModel={idfPaginationModel}
                onPaginationModelChange={setIdfPaginationModel}
                getRowClassName={(params) =>
                  params.row.status === "CLOSED"
                    ? classes.closedIDFRow
                    : classes.openIDFRow
                }
                checkboxSelection
                disableRowSelectionOnClick
                density="compact"
                disableColumnSelector={true}
                onRowSelectionModelChange={(selection: GridRowSelectionModel) =>
                  handleIDFSelection(selection)
                }
                onFilterModelChange={(fm: GridFilterModel) => setGridFilterModel(fm)}
                loading={isGridLoading}
                slots={{
                  toolbar: showToolbar,
                  noRowsOverlay: () => (noRecent),
                  noResultsOverlay: () => (NoFiltered),
                }}
              />
            </Box>
          </Box>
          <Grid item xs={12}>
            {dispatchStore.dispatchEvents.pagingKeys && (
              <Box mt={1} mb={1} style={{ float: "right" }}>
                <Button variant="contained" color="inherit" size="small" onClick={handleDispatchPaging}>
                  Load More Events
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <AdditionalDetailsModal
        props={{
          isOpen: addtlDetailsOpen,
          idfEvent: addtlDetails,
          handleClose: () => setaddtlDetailsOpen(false),
        }}
      />
    </Box>
  );
};

export default DispatchEventFeedGrid;
